import 'owl.carousel';

export default function home() {
	// Carousel
	$( '#home-hero .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		autoplay: true,
		autoplayTimeout: 7000,
		autoplayHoverPause: true,
		smartSpeed: 1000,
		navText: [
			'<i class="fas fa-arrow-left" aria-hidden="true"><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-right" aria-hidden="true"><span class="sr-only">Next</span>',
		]
	} );

	// Videos
	$( '#videos .videos>.video .video-summary a' ).on( 'click', function ( e ) {
		e.preventDefault();

		$( this ).closest( '.video' ).addClass( 'open' );
	} );

	$( '#videos .videos>.video .video-embed a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).closest( '.video' ).removeClass( 'open' );
		const src = $( this ).prev( '.embed-container' ).find( 'iframe' ).attr( 'src' );
		$( this ).prev( '.embed-container' ).find( 'iframe' ).attr( 'src', src );
	} );
}
