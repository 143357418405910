import nav from './modules/nav';
import home from './modules/home';
import share from './modules/share';
import videos from './modules/videos';
import music from './modules/music';

jQuery( document ).ready( function ( $ ) {
	nav();
	home();
	share();
	videos();
	music();
} );