import 'magnific-popup';

export default function videos() {
	$( '.videos-grid>article>a' ).magnificPopup( {
		type: 'iframe',
		gallery: {
			enabled: true
		},
		iframe: {
			markup: '<div class="mfp-iframe-scaler">' +
			        '<div class="mfp-close"></div>' +
			        '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
			        '<div class="mfp-title">Some caption</div>' +
			        '</div>'
		},
		callbacks: {
			markupParse: function ( template, values, item ) {
				const title = item.el.attr( 'title' );
				const permalink = item.el.attr( 'data-permalink' );

				values.title = '<div>' + title + '</div>'
				               + '<div><ul class="modal-share">'
				               + '<li>Share:</li>'
				               + '<li><a href="https://www.facebook.com/sharer/sharer.php?u=' + permalink + '" target="_blank" aria-label="Facebook"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>'
				               + '<li><a href="https://twitter.com/intent/tweet?text=&url=' + permalink + '" target="_blank" aria-label="Twitter"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>'
				               + '<li><a href="mailto:?subject=' + title + '&body=' + permalink + '" target="_blank" aria-label="Email"><i class="far fa-envelope" aria-hidden="true"></i></a></li>'
				               + '</ul></div>';
			}
		},
	} );
}