export default function nav() {
	$( '#header .hamburger' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#header #nav-collapse' ).slideToggle();
	} );

	/*$( '.btn-newsletter' ).magnificPopup( {
		type: 'inline',
		midClick: true
	} );*/
}